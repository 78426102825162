import _resolvePathname from "resolve-pathname";
import _valueEqual from "value-equal";
import _tinyWarning from "tiny-warning";
import _tinyInvariant from "tiny-invariant";
var exports = {};

function _interopDefault(t) {
  return t && "object" == typeof t && "default" in t ? t.default : t;
}

Object.defineProperty(exports, "__esModule", {
  value: !0
});

var resolvePathname = _interopDefault(_resolvePathname),
    valueEqual = _interopDefault(_valueEqual);

_tinyWarning;

var invariant = _interopDefault(_tinyInvariant);

function _extends() {
  return (_extends = Object.assign || function (t) {
    for (var n = 1; n < arguments.length; n++) {
      var e = arguments[n];

      for (var a in e) Object.prototype.hasOwnProperty.call(e, a) && (t[a] = e[a]);
    }

    return t;
  }).apply(this, arguments);
}

function addLeadingSlash(t) {
  return "/" === t.charAt(0) ? t : "/" + t;
}

function stripLeadingSlash(t) {
  return "/" === t.charAt(0) ? t.substr(1) : t;
}

function hasBasename(t, n) {
  return 0 === t.toLowerCase().indexOf(n.toLowerCase()) && -1 !== "/?#".indexOf(t.charAt(n.length));
}

function stripBasename(t, n) {
  return hasBasename(t, n) ? t.substr(n.length) : t;
}

function stripTrailingSlash(t) {
  return "/" === t.charAt(t.length - 1) ? t.slice(0, -1) : t;
}

function parsePath(t) {
  var n = t || "/",
      e = "",
      a = "",
      r = n.indexOf("#");
  -1 !== r && (a = n.substr(r), n = n.substr(0, r));
  var o = n.indexOf("?");
  return -1 !== o && (e = n.substr(o), n = n.substr(0, o)), {
    pathname: n,
    search: "?" === e ? "" : e,
    hash: "#" === a ? "" : a
  };
}

function createPath(t) {
  var n = t.pathname,
      e = t.search,
      a = t.hash,
      r = n || "/";
  return e && "?" !== e && (r += "?" === e.charAt(0) ? e : "?" + e), a && "#" !== a && (r += "#" === a.charAt(0) ? a : "#" + a), r;
}

function createLocation(t, n, e, a) {
  var r;
  "string" == typeof t ? (r = parsePath(t)).state = n : (void 0 === (r = _extends({}, t)).pathname && (r.pathname = ""), r.search ? "?" !== r.search.charAt(0) && (r.search = "?" + r.search) : r.search = "", r.hash ? "#" !== r.hash.charAt(0) && (r.hash = "#" + r.hash) : r.hash = "", void 0 !== n && void 0 === r.state && (r.state = n));

  try {
    r.pathname = decodeURI(r.pathname);
  } catch (t) {
    throw t instanceof URIError ? new URIError("Pathname \"" + r.pathname + "\" could not be decoded. This is likely caused by an invalid percent-encoding.") : t;
  }

  return e && (r.key = e), a ? r.pathname ? "/" !== r.pathname.charAt(0) && (r.pathname = resolvePathname(r.pathname, a.pathname)) : r.pathname = a.pathname : r.pathname || (r.pathname = "/"), r;
}

function locationsAreEqual(t, n) {
  return t.pathname === n.pathname && t.search === n.search && t.hash === n.hash && t.key === n.key && valueEqual(t.state, n.state);
}

function createTransitionManager() {
  var o = null;
  var a = [];
  return {
    setPrompt: function (t) {
      return o = t, function () {
        o === t && (o = null);
      };
    },
    confirmTransitionTo: function (t, n, e, a) {
      if (null != o) {
        var r = "function" == typeof o ? o(t, n) : o;
        "string" == typeof r ? "function" == typeof e ? e(r, a) : a(!0) : a(!1 !== r);
      } else a(!0);
    },
    appendListener: function (t) {
      var n = !0;

      function e() {
        n && t.apply(void 0, arguments);
      }

      return a.push(e), function () {
        n = !1, a = a.filter(function (t) {
          return t !== e;
        });
      };
    },
    notifyListeners: function () {
      for (var t = arguments.length, n = new Array(t), e = 0; e < t; e++) n[e] = arguments[e];

      a.forEach(function (t) {
        return t.apply(void 0, n);
      });
    }
  };
}

var canUseDOM = !("undefined" == typeof window || !window.document || !window.document.createElement);

function getConfirmation(t, n) {
  n(window.confirm(t));
}

function supportsHistory() {
  var t = window.navigator.userAgent;
  return (-1 === t.indexOf("Android 2.") && -1 === t.indexOf("Android 4.0") || -1 === t.indexOf("Mobile Safari") || -1 !== t.indexOf("Chrome") || -1 !== t.indexOf("Windows Phone")) && window.history && "pushState" in window.history;
}

function supportsPopStateOnHashChange() {
  return -1 === window.navigator.userAgent.indexOf("Trident");
}

function supportsGoWithoutReloadUsingHash() {
  return -1 === window.navigator.userAgent.indexOf("Firefox");
}

function isExtraneousPopstateEvent(t) {
  return void 0 === t.state && -1 === navigator.userAgent.indexOf("CriOS");
}

var PopStateEvent = "popstate",
    HashChangeEvent = "hashchange";

function getHistoryState() {
  try {
    return window.history.state || {};
  } catch (t) {
    return {};
  }
}

function createBrowserHistory(t) {
  void 0 === t && (t = {}), canUseDOM || invariant(!1);
  var s = window.history,
      c = supportsHistory(),
      n = !supportsPopStateOnHashChange(),
      e = t,
      a = e.forceRefresh,
      h = void 0 !== a && a,
      r = e.getUserConfirmation,
      u = void 0 === r ? getConfirmation : r,
      o = e.keyLength,
      i = void 0 === o ? 6 : o,
      f = t.basename ? stripTrailingSlash(addLeadingSlash(t.basename)) : "";

  function l(t) {
    var n = t || {},
        e = n.key,
        a = n.state,
        r = window.location,
        o = r.pathname + r.search + r.hash;
    return f && (o = stripBasename(o, f)), createLocation(o, a, e);
  }

  function d() {
    return Math.random().toString(36).substr(2, i);
  }

  var v = createTransitionManager();

  function p(t) {
    _extends(T, t), T.length = s.length, v.notifyListeners(T.location, T.action);
  }

  function g(t) {
    isExtraneousPopstateEvent(t) || w(l(t.state));
  }

  function P() {
    w(l(getHistoryState()));
  }

  var m = !1;

  function w(n) {
    if (m) m = !1, p();else {
      v.confirmTransitionTo(n, "POP", u, function (t) {
        t ? p({
          action: "POP",
          location: n
        }) : function (t) {
          var n = T.location,
              e = H.indexOf(n.key);
          -1 === e && (e = 0);
          var a = H.indexOf(t.key);
          -1 === a && (a = 0);
          var r = e - a;
          r && (m = !0, L(r));
        }(n);
      });
    }
  }

  var y = l(getHistoryState()),
      H = [y.key];

  function x(t) {
    return f + createPath(t);
  }

  function L(t) {
    s.go(t);
  }

  var O = 0;

  function E(t) {
    1 === (O += t) && 1 === t ? (window.addEventListener(PopStateEvent, g), n && window.addEventListener(HashChangeEvent, P)) : 0 === O && (window.removeEventListener(PopStateEvent, g), n && window.removeEventListener(HashChangeEvent, P));
  }

  var S = !1;
  var T = {
    length: s.length,
    action: "POP",
    location: y,
    createHref: x,
    push: function (t, n) {
      var i = createLocation(t, n, d(), T.location);
      v.confirmTransitionTo(i, "PUSH", u, function (t) {
        if (t) {
          var n = x(i),
              e = i.key,
              a = i.state;
          if (c) {
            if (s.pushState({
              key: e,
              state: a
            }, null, n), h) window.location.href = n;else {
              var r = H.indexOf(T.location.key),
                  o = H.slice(0, r + 1);
              o.push(i.key), H = o, p({
                action: "PUSH",
                location: i
              });
            }
          } else window.location.href = n;
        }
      });
    },
    replace: function (t, n) {
      var o = "REPLACE",
          i = createLocation(t, n, d(), T.location);
      v.confirmTransitionTo(i, o, u, function (t) {
        if (t) {
          var n = x(i),
              e = i.key,
              a = i.state;
          if (c) {
            if (s.replaceState({
              key: e,
              state: a
            }, null, n), h) window.location.replace(n);else {
              var r = H.indexOf(T.location.key);
              -1 !== r && (H[r] = i.key), p({
                action: o,
                location: i
              });
            }
          } else window.location.replace(n);
        }
      });
    },
    go: L,
    goBack: function () {
      L(-1);
    },
    goForward: function () {
      L(1);
    },
    block: function (t) {
      void 0 === t && (t = !1);
      var n = v.setPrompt(t);
      return S || (E(1), S = !0), function () {
        return S && (S = !1, E(-1)), n();
      };
    },
    listen: function (t) {
      var n = v.appendListener(t);
      return E(1), function () {
        E(-1), n();
      };
    }
  };
  return T;
}

var HashChangeEvent$1 = "hashchange",
    HashPathCoders = {
  hashbang: {
    encodePath: function (t) {
      return "!" === t.charAt(0) ? t : "!/" + stripLeadingSlash(t);
    },
    decodePath: function (t) {
      return "!" === t.charAt(0) ? t.substr(1) : t;
    }
  },
  noslash: {
    encodePath: stripLeadingSlash,
    decodePath: addLeadingSlash
  },
  slash: {
    encodePath: addLeadingSlash,
    decodePath: addLeadingSlash
  }
};

function stripHash(t) {
  var n = t.indexOf("#");
  return -1 === n ? t : t.slice(0, n);
}

function getHashPath() {
  var t = window.location.href,
      n = t.indexOf("#");
  return -1 === n ? "" : t.substring(n + 1);
}

function pushHashPath(t) {
  window.location.hash = t;
}

function replaceHashPath(t) {
  window.location.replace(stripHash(window.location.href) + "#" + t);
}

function createHashHistory(t) {
  void 0 === t && (t = {}), canUseDOM || invariant(!1);
  var n = window.history,
      e = (supportsGoWithoutReloadUsingHash(), t),
      a = e.getUserConfirmation,
      i = void 0 === a ? getConfirmation : a,
      r = e.hashType,
      o = void 0 === r ? "slash" : r,
      s = t.basename ? stripTrailingSlash(addLeadingSlash(t.basename)) : "",
      c = HashPathCoders[o],
      h = c.encodePath,
      u = c.decodePath;

  function f() {
    var t = u(getHashPath());
    return s && (t = stripBasename(t, s)), createLocation(t);
  }

  var l = createTransitionManager();

  function d(t) {
    _extends(E, t), E.length = n.length, l.notifyListeners(E.location, E.action);
  }

  var v = !1,
      p = null;

  function g() {
    var t = getHashPath(),
        n = h(t);
    if (t !== n) replaceHashPath(n);else {
      var e = f(),
          a = E.location;
      if (!v && function (t, n) {
        return t.pathname === n.pathname && t.search === n.search && t.hash === n.hash;
      }(a, e)) return;
      if (p === createPath(e)) return;
      p = null, function (n) {
        if (v) v = !1, d();else {
          l.confirmTransitionTo(n, "POP", i, function (t) {
            t ? d({
              action: "POP",
              location: n
            }) : function (t) {
              var n = E.location,
                  e = y.lastIndexOf(createPath(n));
              -1 === e && (e = 0);
              var a = y.lastIndexOf(createPath(t));
              -1 === a && (a = 0);
              var r = e - a;
              r && (v = !0, H(r));
            }(n);
          });
        }
      }(e);
    }
  }

  var P = getHashPath(),
      m = h(P);
  P !== m && replaceHashPath(m);
  var w = f(),
      y = [createPath(w)];

  function H(t) {
    n.go(t);
  }

  var x = 0;

  function L(t) {
    1 === (x += t) && 1 === t ? window.addEventListener(HashChangeEvent$1, g) : 0 === x && window.removeEventListener(HashChangeEvent$1, g);
  }

  var O = !1;
  var E = {
    length: n.length,
    action: "POP",
    location: w,
    createHref: function (t) {
      var n = document.querySelector("base"),
          e = "";
      return n && n.getAttribute("href") && (e = stripHash(window.location.href)), e + "#" + h(s + createPath(t));
    },
    push: function (t, n) {
      var o = createLocation(t, void 0, void 0, E.location);
      l.confirmTransitionTo(o, "PUSH", i, function (t) {
        if (t) {
          var n = createPath(o),
              e = h(s + n);

          if (getHashPath() !== e) {
            p = n, pushHashPath(e);
            var a = y.lastIndexOf(createPath(E.location)),
                r = y.slice(0, a + 1);
            r.push(n), y = r, d({
              action: "PUSH",
              location: o
            });
          } else d();
        }
      });
    },
    replace: function (t, n) {
      var r = "REPLACE",
          o = createLocation(t, void 0, void 0, E.location);
      l.confirmTransitionTo(o, r, i, function (t) {
        if (t) {
          var n = createPath(o),
              e = h(s + n);
          getHashPath() !== e && (p = n, replaceHashPath(e));
          var a = y.indexOf(createPath(E.location));
          -1 !== a && (y[a] = n), d({
            action: r,
            location: o
          });
        }
      });
    },
    go: H,
    goBack: function () {
      H(-1);
    },
    goForward: function () {
      H(1);
    },
    block: function (t) {
      void 0 === t && (t = !1);
      var n = l.setPrompt(t);
      return O || (L(1), O = !0), function () {
        return O && (O = !1, L(-1)), n();
      };
    },
    listen: function (t) {
      var n = l.appendListener(t);
      return L(1), function () {
        L(-1), n();
      };
    }
  };
  return E;
}

function clamp(t, n, e) {
  return Math.min(Math.max(t, n), e);
}

function createMemoryHistory(t) {
  void 0 === t && (t = {});
  var n = t,
      r = n.getUserConfirmation,
      e = n.initialEntries,
      a = void 0 === e ? ["/"] : e,
      o = n.initialIndex,
      i = void 0 === o ? 0 : o,
      s = n.keyLength,
      c = void 0 === s ? 6 : s,
      h = createTransitionManager();

  function u(t) {
    _extends(g, t), g.length = g.entries.length, h.notifyListeners(g.location, g.action);
  }

  function f() {
    return Math.random().toString(36).substr(2, c);
  }

  var l = clamp(i, 0, a.length - 1),
      d = a.map(function (t) {
    return createLocation(t, void 0, "string" == typeof t ? f() : t.key || f());
  }),
      v = createPath;

  function p(t) {
    var n = clamp(g.index + t, 0, g.entries.length - 1),
        e = g.entries[n];
    h.confirmTransitionTo(e, "POP", r, function (t) {
      t ? u({
        action: "POP",
        location: e,
        index: n
      }) : u();
    });
  }

  var g = {
    length: d.length,
    action: "POP",
    location: d[l],
    index: l,
    entries: d,
    createHref: v,
    push: function (t, n) {
      var a = createLocation(t, n, f(), g.location);
      h.confirmTransitionTo(a, "PUSH", r, function (t) {
        if (t) {
          var n = g.index + 1,
              e = g.entries.slice(0);
          e.length > n ? e.splice(n, e.length - n, a) : e.push(a), u({
            action: "PUSH",
            location: a,
            index: n,
            entries: e
          });
        }
      });
    },
    replace: function (t, n) {
      var e = "REPLACE",
          a = createLocation(t, n, f(), g.location);
      h.confirmTransitionTo(a, e, r, function (t) {
        t && (g.entries[g.index] = a, u({
          action: e,
          location: a
        }));
      });
    },
    go: p,
    goBack: function () {
      p(-1);
    },
    goForward: function () {
      p(1);
    },
    canGo: function (t) {
      var n = g.index + t;
      return 0 <= n && n < g.entries.length;
    },
    block: function (t) {
      return void 0 === t && (t = !1), h.setPrompt(t);
    },
    listen: function (t) {
      return h.appendListener(t);
    }
  };
  return g;
}

exports.createBrowserHistory = createBrowserHistory, exports.createHashHistory = createHashHistory, exports.createMemoryHistory = createMemoryHistory, exports.createLocation = createLocation, exports.locationsAreEqual = locationsAreEqual, exports.parsePath = parsePath, exports.createPath = createPath;
export default exports;